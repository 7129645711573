header {
  height: 100vh;
  overflow: hidden;
  margin-bottom: -10rem;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* =========== CTA =========== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* =========== HEADER SOCIALS =========== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  z-index: 100;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ============ Pres ============= */
.pres {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 15.6rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 1.5rem;
  z-index: 100;
}

/* =========== SCROLL DOWN ============= */
.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 25rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1rem;
  z-index: 100;
}

.slidingText {
  position: absolute;
  font-size: 50vh;
  bottom: 20px;
  white-space: nowrap;
  color: #ffffff18;
  width: 165%;
  font-weight: bold;
}

/* =========== MEDIA QUERIES (TABLET DEVICES) ========== */
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }

  .slidingText {
    bottom: -30px;
    width: 280%;
  }
}

/* =========== MEDIA QUERIES (MOBILE DEVICES) ========== */
@media screen and (max-width: 738px) {
  header {
    height: 100vh;
    margin-bottom: 1rem;
    margin-top: -3rem;
  }

  .header__socials,
  .scroll_down {
    display: none;
  }

  .slidingText {
    width: 610%;
    bottom: -50px;
  }
}
