.experience__container {
  width: 40%;
  grid-template-columns: 1fr;
  margin-bottom: 15rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  row-gap: 2rem;
  margin-left: 3rem;
}

.experience_details {
  display: flex;
  gap: 1rem;
}

.experience_details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/* =========== MEDIA QUERIES (TABLET DEVICES) ========== */
@media screen and (max-width: 1024px) {
  .experience__container {
    width: 80%;
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience__content {
    padding: 1rem;
  }
}
/* =========== MEDIA QUERIES (MOBILE DEVICES) ========== */
@media screen and (max-width: 738px) {
  .experience__container {
    width: 90%;
    gap: 0rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }

  .experience__content {
    width: 50%;
    margin-left: 0;
    row-gap: 1rem;
    gap: 2rem;
  }
}
