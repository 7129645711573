nav {
  background: rgb(170, 176, 176, 0.5);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 34%;
  transform-origin: tranlateX(-50%);
  bottom: 1rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.5rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
}

/* =========== MEDIA QUERIES (TABLET DEVICES) ========== */
@media screen and (max-width: 1024px) {
  nav {
    left: 27%;
  }
}

/* =========== MEDIA QUERIES (MOBILE DEVICES) ========== */
@media screen and (max-width: 600px) {
  nav {
    left: 3%;
    gap: 0.4rem;
    padding: 0.2rem 0.2rem;
    z-index: 100;
  }

  nav a {
    font-size: 1.3rem;
  }
}
